if (typeof window.ResizeObserver !== 'undefined') {
  // There were failing cypress tests on (uncaught exception) Error: ResizeObserver loop completed with undelivered notifications.
  // The solution is inspired by https://github.com/cypress-io/cypress/issues/22113#issuecomment-1548030835
  // we're already using the approach on other places but the issue may occure in 3rd party libs and this should fix it
  class CalmResizeObserver extends ResizeObserver {
    constructor(callback: ResizeObserverCallback) {
      super((entries, observer) => {
        requestAnimationFrame(() => {
          if (Array.isArray(entries) && entries.length > 0) {
            callback(entries, observer)
          }
        })
      })
    }
  }

  window.ResizeObserver = CalmResizeObserver
}
